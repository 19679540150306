import React from 'react';
import Meta from '../components/common/Meta';

const Error_page = () => {
  return (
    <div>
      <Meta title="404 || Nusa | NFT Marketplace" />
      <div className="pt-[5.5rem] lg:pt-24 ">
        {/* <!-- 404 --> */}
        <section className="dark:bg-dark-navy relative py-16 md:py-24 bg-green-radial-404 min-h-[calc(100vh-96px)] flex justify-center items-center">
          <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
            <img src="/images/gradient_light.jpg" alt="gradient" className="h-full w-full" />
          </picture>
          <div className="container">
            <div className="mx-auto max-w-lg text-center">
              <h1 className="font-display text-4xl md:text-6xl font-bold text-transparent bg-clip-text bg-green-gradation-light tracking-wide">
                404
              </h1>
              <h1 className="font-display mb-10 text-lg  md:text-2xl font-bold text-transparent bg-clip-text bg-green-gradation-light tracking-wide">
                Page Not Found!
              </h1>
              <p className="dark:text-grey mb-12 text-sm md:text-base leading-normal">
                Oops! The page you are looking for does not exist. It might have been moved or
                deleted.
              </p>
            </div>
          </div>
        </section>
        {/* <!-- end 404 --> */}
      </div>
    </div>
  );
};

export default Error_page;
